.category-card{
    $this: &;
    position: relative;
    padding: 25px 30px;
    background-color: #fff;
    height: 240px;
    overflow: hidden;
    transition: background-color $tr-time;
    &:hover{
        background-color: $main-clr;
        #{$this}__icon{
            fill: $main-clr-hover !important;
        }
    }
    &:last-child{
        #{$this}__icon{
            fill: #E3E3E3;
        }
    }
    &__title{
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #282828;
        z-index: 5;
        @include _576{
            font-size: 18px;
            line-height: 20px;
        }
    }
    &__thumb{
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__icon{
        width: 55%;
        height: 55%;
        position: absolute;
        bottom: 0;
        right: -35px;
        fill: transparent;
        transition: fill $tr-time;
        z-index: 3;
    }
    &__arrow{
        position: absolute;
        transform: rotate(180deg);
        width: 40px;
        height: 25px;
        fill: $alt-clr;
        right: 35px;
        bottom: 35px;
        z-index: 4;
        @include _576{
            width: 20px;
            height: 16px;
            right: 30px;
            bottom: 25px;
        }
    }
}