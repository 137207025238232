


@import 'base/app';
@import 'components/category-card';
@import 'components/countdown';
@import 'components/product-card';
@import 'components/content/seo';
@import 'components/content/text';




// --- Video slider ------------------------------------------
.index-video-slider {
    .container {
        overflow: hidden;
    }
    &.text {
        background: #272727;
    }
    position: relative;
    margin-top: 74px;

    @include _1920{
        margin-top: cfl(49.3,74);
    }
    @include _992{
        margin-top: 40px;
    }
    @include _576{
        margin-top: cflm(25,35);
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        @include _1920{
            margin-bottom: cfl(33.3,50);
        }
        @include _992{
            margin-bottom: 15px;
        }
        span {
            text-transform: uppercase;
            font-size: 52.5px;
            line-height: 64px;
            @include _1920{
                font-size: cfl(35, 52.5);
                line-height: cfl(42.6,64);
            }
            @include _576{
                font-size: 14px;
                line-height: 16px;
                width: 65%;
            }
        }
    }

    .swiper-pagination {
        &__container{
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 91px;

            left: 0;
            right: 0;

            height: 65%;
            align-items: center;

            &.text {
                @include _992 {
                    height: 135%;
                }
            }
            @include _1920{
                bottom: cfl(60,91);
                height: 70%;
                @include _1650{
                    height: 80%;
                    @include _992 {
                        height: 90%;
                        bottom: 10px;
                        @include _768 {
                            height: 100%;
                            @include _576 {
                                height: 110%;
                            }
                        }
                    }
                }
            }
        }

        &__arrow{
            z-index: 5;
            border: 1px solid transparent;
            border-radius: 50%;
            padding: 23px;
            transition: all .3s ease;
            cursor: pointer;
            @include _1920{
                padding: cfl(16,23);
            }
            @include _992{
                padding: 20px;
            }
            @include _576{
                padding: 0;
            }
            svg {
                width: 24px;
                height: 24px;
                fill: #fff;
                z-index: 100;
                @include _1920{
                    width: cfl(16,24);
                    height: cfl(16,24);
                }
                @include _992{
                    width: 16px;
                    height: 16px;
                }
            }
            &:last-child{
                transform: rotate(180deg);
            }
        }
    }
}


.video-slider {
    position: relative;
    $_: &;

    &__slide.swiper-slide {
        display: flex;
        align-items: center;
        flex-direction: column;


        &.text {
            flex-direction: row;
            justify-content: space-evenly;

            padding: 55px 0;

            @include _992 {
                flex-direction: column-reverse;
            }

            #{$_}__preview {
                display: flex;
                justify-content: center;
                width: fit-content;
                height: inherit;

                #{$_}__image {
                    width: 570px;
                    @include _1200 {
                        max-width: 475px;

                        @include _992 {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }

            }


            #{$_}__content {
                max-width: 530px;

                @include _992 {
                    max-width: 100%;

                    margin-top: 30px;
                }

                #{$_}__title {
                    font-size: 42px;
                    line-height: 42px;
                    text-align: left;
                    text-transform: uppercase;

                    @include _1920{
                        font-size: cfl(35, 42);
                        line-height: cfl(35, 42);
                    }
                }

                #{$_}__description {
                    font-size: 21px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #ADACAC;

                    margin: 30px 0;

                    @include _1920{
                        font-size: cfl(14, 21);
                        line-height: cfl(22, 33);
                    }
                    @include _992{
                        font-size: 12px;
                        line-height: 18px;
                        margin: 15px 0;
                    }
                }

                #{$_}__button {
                    display: inline-block;
                    border: 2px solid #C80000;
                    padding: 13px 42px;
                    border-radius: 27px;

                    @include _1920{
                        font-size: cfl(14,21);
                        line-height: cfl(22,33);
                        margin: cfl(30,45) 0;
                    }
                    @include _992{
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }

    }

    &__text {
        padding: 10px 0;

        .title {
            font-size: 17px;
            line-height: 22px;
        }

        .text {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400px;

            color: #ADACAC;

            margin-top: 10px;
        }
    }

    &__preview {
        position: relative;
        overflow: hidden;

        height: 322px;
        @include _1280 {
            height: 260px;
        }
    }

    &__image {
        height: 322px;
        @include _1280 {
            height: initial;
        }
    }

    &__image, &__preview, &__text {
        width: 100%;
        border-radius: 14px;
    }

    .video-play {
        position: absolute;
        top: 0;

        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        &__svg {
            width: 81px;
            height: 54px;
            @include _576 {
                width: 54px;
            }
            rect {
                transition: fill .15s ease-in-out;
            }
        }
        &:hover {
            svg {
                rect {
                    fill: #EB1616;
                }
            }
        }
    }
}
// --- End Video slider ------------------------------------------




.index-slider{
    &__wrapper{
        overflow: hidden;
    }
    &__inner{
        height: 778px;
        overflow: hidden;
        @include _1920{
            height: cfl(519, 778);
        }
        @include _576{
            height: cflm(250, 350);
        }
    }
    &__slide{
        height: 100%;
        position: relative;
        overflow: hidden;
        &-bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: top right;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;
            @include _576{
                filter: brightness(.5);
                background-position: top right -60px;
            }
        }
        &-content{
            height: 100%;
            padding: 117px 0 90px 0;
            display: flex;
            flex-direction: column;
            @include _1920{
                padding: cfl(80,117) 0 cfl(60,90) 0;
            }
            @include _992{
                padding: 35px 0;
            }
            @include _576{
                max-width:  100%;
                padding: 30px 0 10px;
            }
            >a{
            }
        }
        &-title{
            >p{
                text-transform: uppercase;
                font-size: 61px;
                line-height: 72px;
                color: #FFFFFF;
                @include _1920{
                    font-size: cfl(41,61);
                    line-height: cfl(48,72);
                }
                @include _992{
                    font-size: 36px;
                    line-height: 40px;
                }
                @include _576{
                    font-size: cflm(16, 20);
                    line-height: cflm(21, 26);
                }
            }
            >span{
                display: inline-block;
                width: 50%;
                font-size: 25px;
                line-height: 33px;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: 38px;
                @include _1920{
                    font-size: cfl(17,25);
                    line-height: cfl(22, 33);
                    margin-top: cfl(22, 38);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 20px;
                    width: 60%;
                }
                @include _576{
                    font-size: cflm(14,16);
                    line-height: cflm(16, 18);
                    width: 80%;
                    margin-top: cflm(20, 30);
                }
            }
        }
        &-label{
            position: absolute;
            top: 50px;
            right: 0;
            &::before,
            &::after{
                content: '';
                display: block;
                width: 200px;
                height: 85px;
                position: absolute;
                top: 0;
                left: 0;
                @include _576{
                    height: 65px;
                }
            }
            p{
                font-size: 18px;
                line-height: 20px;
                color: #282828;
                z-index: 1;
                position: relative;
                padding: 10px 12px 20px;
                @include _576{
                    font-size: 12px;
                    line-height: 16px;
                    padding: 5px 12px 10px;
                }
                b{
                    font-size: 32px;
                    line-height: 34px;
                    @include _576{
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
    &__container{
        height: 100%;
        overflow: hidden;
        position: relative;
        .swiper{
            height: 100%;
        }
        .swiper-slide{
            height: 100%;
            display: block;
        }
        .swiper-pagination{
            bottom: 20px;
            left: 0;
            right: 0;
            @include _1920{
                bottom: cfl(20, 15);
            }
            @include _576{
                bottom: 0;
            }
            &__container{
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 91px;
                padding: 0 3.85%;
                left: 0;
                right: 0;
                @include _1920{
                    bottom: cfl(60,91);
                }
                @include _992{
                    bottom: 10px;
                }
            }
            &__arrow{
                z-index: 5;
                border: 1px solid transparent;
                border-radius: 50%;
                padding: 23px;
                transition: all .3s ease;
                cursor: pointer;
                @include _1920{
                    padding: cfl(16,23);
                }
                @include _992{
                    padding: 20px;
                }
                @include _576{
                    padding: 0;
                }
                svg{
                    width: 24px;
                    height: 24px;
                    fill: #fff;
                    z-index: 100;
                    @include _1920{
                        width: cfl(16,24);
                        height: cfl(16,24);
                    }
                    @include _992{
                        width: 16px;
                        height: 16px;
                    }
                    
                }
                &:last-child{
                    transform: rotate(180deg);
                }
                &:hover{
                    border: 1px solid $main-clr;
                    @include _992{
                        border: none;
                    }
                }
            }
            ul{
                li{
                    display: inline-block;
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 27px;
                    transition: all .3s ease;
                    height: 30px;
                    width: 30px;
                    @include _1920{
                        font-size: cfl(15, 22);
                        line-height: cfl(18.3, 27);
                        height: cfl(20,30);
                        width: cfl(20,30);
                    }
                    @include _992{
                        font-size: 15px;
                        line-height: 18px;
                        height: 20px;
                        width: 20px;
                    }
                    &:not(:last-child){
                        margin-right: 54px;
                        @include _1920{
                            margin-right: cfl(37,54);
                        }
                    }
                    &.active{
                        background-color: #fff;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}


.index-categories{
    &__tabs{
        margin-top: 89px;
        @include _1920{
            margin-top: cfl(60, 89);
        }
        @include _576{
            margin-top: cflm(30,40);
        }
        &__btns{
            display: grid;
            column-gap: 40px;
            grid-template-columns: repeat(6, 1fr);
            @include _1920{
                column-gap: cfl(27, 40);
            }
            @include _992{
                row-gap: 20px;
                grid-template-columns: repeat(3, 1fr);

            }
            &__item{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 25px;
                line-height: 31px;
                color: #878787;
                transition: all .3s ease;
                cursor: pointer;
                text-align: center;
                @include _1920{
                    font-size: cfl(17, 25);
                    line-height: cfl(21,31);
                }
                @include _992{
                    font-size: 13px;
                    line-height: 17px;
                }
                @include _576{
                    font-size:  cflm(10, 12);
                    line-height: cflm(12, 14);
                }
                svg{
                    height: 70px;
                    width: 70px; 
                    fill: #ADACAC;
                    transition: all .3s ease;
                    margin-bottom: 34px;
                    @include _1920{
                        height: cfl(47, 70);
                        width: cfl(47, 70);
                        margin-bottom: cfl(23,34);
                    }
                    @include _992{
                        height: 40px;
                        width: 40px;
                    }
                    @include _576{
                        height: cflm(35,40);
                        width: cflm(35,40);
                    }
                }
                &:hover{
                    color: $main-clr;
                    svg{
                        fill: $main-clr;
                    }
                    @include _992{
                        color: #878787;
                        svg{
                            fill: #ADACAC;
                        }
                    }

                }
                &.active{
                    color: #ff0000;
                    svg{
                        fill: #ff0000;
                    }
                }
            }
        }
        &__content{
            margin-top: 105px;
            @include _1920{
                margin-top: cfl(67, 105);
            }
            @include _576{
                margin-top: cflm(30,45);
            }
            .product-list{
                display: none;
                grid-template-columns: repeat(4, 1fr);
                column-gap: 45px;
                row-gap: 46px;
                @include _1920{
                    column-gap: cfl(30,45);
                    row-gap: cfl(31,46);
                }
                @include _576{
                    grid-template-columns: repeat(2, 1fr);
                }
                &.active{
                    display: grid;
                }
                .slide-card{
                    grid-column: 1/3;
                    height: 638px;
                    @include _1920{
                        height: cfl(426, 638);
                    }
                    &:hover{
                        .slide-card__bg{
                            transform: translateY(-110px);
                            @include _1920{
                                transform: translateY(cfl(-70,-110));
                            }
                            @include _992{
                                transform: none;
                            }
                        }
                    }
                    &__link{
                        height: 110px;
                        bottom: 110px;
                        margin-top: 0px;
                        @include _1920{
                            height: cfl(73.3, 110);
                            bottom: cfl(73.3, 110);
                        }
                        @include _992{
                            height: 50px;
                            bottom: 50px;
                        }
                        @include _576{
                            height: cflm(40,45);
                            bottom: cflm(40,45);
                        }
                        span{
                            font-weight: 600;
                            @include _992{
                                font-size: 14px;
                                line-height: 16px;
                            }
                            @include _576{
                                font-size: cflm(12,14);
                                line-height: cflm(14,16);
                            }
                        }
                    }
                }
                &__item{
                    &.product-list__item-category{
                        grid-column: 1/3;
                    }
                    &-category{
                        border-radius: 21px;
                        overflow: hidden;
                        background-color: $main-clr;
                        &__bg{
                            height: 640px;
                            background-repeat: no-repeat;
                            background-size: cover;
                            transition: all .3s ease;
                        }
                        &__bg-bottom{
                            transition: transform .3s ease;
                            background: $main-clr;
                        }
                        span{
                            position: absolute;
                            margin-top: -72px;
                            margin-left: 37px;
                            font-size: 25.9px;
                            svg{
                                transform: rotate(180deg);
                                fill: #fff;
                                height: 13px;
                                width: 28px;
                                margin-left: 100px;
                                margin-top: -5px;
                            }
                        }
                        &:hover{
                            .product-list__item-category__bg{
                                transform: translateY(-105px);
                            }
                        }
                    }
                }
            }
        }
    }
}

.index-products{
    margin-top: 74px;
    @include _1920{
        margin-top: cfl(49.3,74);
    }
    @include _992{
        margin-top: 40px;
    }
    @include _576{
        margin-top: cflm(25,35);
    }
    &__tabs{
        text-align: center;
        margin-bottom: 46px;
        @include _1920{
            margin-bottom: cfl(30.6,46);
        }
        @include _576{
            margin-bottom: cflm(20,25);
        }
        &__item{
            font-size: 25.5px;
            line-height: 63px;
            font-weight: 600;
            opacity: .5;
            transition: all .3s ease;
            cursor: pointer;
            @include _1920{
                font-size: cfl(17,25.5);
                line-height: cfl(42, 63);
            }
            @include _992{
                font-size: 16px;
                line-height: 30px;
            }
            &:first-child{
            }
            &:hover{
                opacity: 1;
                @include _992{
                    opacity: .5;
                }
            }
            &.active{
                opacity: 1;
                &.index-products__tabs__item{
                    &--new{
                        &::before{
                            width: 143px;
                            @include _1920{
                                width: cfl(95.3, 143);
                            }
                            @include _992{
                                width: 89px;
                            }
                        }
                    }
                    &--popular{
                        &::before{
                            width: 197px;
                            @include _1920{
                                width: cfl(131.3, 197);
                            }
                            @include _992{
                                width: 125px;
                            }
                        }
                    }
                }
            }
            &::before{
                content: '';
                position: absolute;
                transition: all .3s ease;
                margin-top: 50px;
                width: 0px;
                height: 3px;
                background-color: $main-clr;
                @include _1920{
                    margin-top: cfl(33.3, 50);
                    height:  cfl(2,3);
                }
                @include _992{
                    margin-top: 25px;
                    height: 2px;
                }
            }
        }
    }
    &__content{
        display: none;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 45px;
        @include _1920{
            column-gap: cfl(30, 45);
        }
        @include _576{
            grid-template-columns: repeat(2, 1fr);
            row-gap: 15px;
        }
        &.active{
            display: grid;
        }
    }

}

.index-seo{
    &-first{
        margin-top: 49px;
        @include _1920{
            margin-top: cfl(32.6, 49);
        }
        @include _992{
            margin-top: 40px;
        }
        @include _576{
            margin-top: cflm(30,40);
        }
        .content-seo{
            display: grid;
            grid-template-columns: 558px 1fr;
            column-gap: 90px;
            padding: 70px 0 60px;
            @include _1920{
                grid-template-columns: cfl(372, 558) 1fr;
                column-gap: cfl(60,90);
                padding: cfl(46.4,70) 0 cfl(40,60);
            }
            @include _576{
                grid-template-columns: 1fr;
                row-gap: 20px;
            }
            &__image{
                width: 558px;
                max-height: 360px;
                height: 360px;
                @include _1920{
                    // width: cfl(372, 558);
                    // min-height: cfl(240, 360);
                    // height: cfl(240, 360);
                    width: cfl(300,450);
                    height: cfl(370,555);
                    min-height: cfl(370,555);
                }
                @include _576{
                    width: 100%;
                    height: auto;
                }
            }
            &__text{
                h1{
                    font-size: 52.5px;
                    line-height: 63px;
                    font-weight: 500;
                    text-transform: uppercase;
                    width: 100%;
                    @include _1920{
                        font-size: cfl(35, 52.5);
                        line-height: cfl(42, 63);
                    }
                    @include _576{
                        font-size: cflm(16, 18);
                        line-height: cflm(20, 24);
                    }
                }
                p{
                    font-size: 21px;
                    line-height: 33px;
                    color: #ADACAC;
                    margin-top: 45px;
                    
                    @include _1920{
                        font-size: cfl(14,21);
                        line-height: cfl(22,33);
                        margin-top: cfl(30,45);
                    }
                    @include _992{
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
    &-second{
        @include _992{
            margin-top: 40px;
        }
        .content-seo{
            display: grid;
            grid-template-columns: 1fr 450px;
            column-gap: 90px;
            padding: 0px;
            @include _1920{
                grid-template-columns: 1fr cfl(300,450);
                column-gap: cfl(60, 90);
            }
            @include _576{
                grid-template-columns: 1fr;
                row-gap: 20px;
            }
            &__image{
                width: 450px;
                height: 555px;
                max-height: 555px;
                @include _1920{
                    width: cfl(300,450);
                    height: cfl(370,555);
                    min-height: cfl(370,555);
                }
                @include _576{
                    width: 100%;
                    height: auto;
                    order: 1;
                }
            }
            &__text{
                @include _576{
                    order: 2;
                }
                h1{
                    font-size: 52.5px;
                    line-height: 63px;
                    font-weight: 500;
                    text-transform: uppercase;
                    @include _1920{
                        font-size: cfl(35,52.5);
                        line-height: cfl(42, 63);   
                        text-transform: none;
                    }
                }
                p{
                    font-size: 21px;
                    line-height: 33px;
                    color: #ADACAC;
                    margin-top: 30px;
                    @include _1920{
                        font-size: cfl(14,21);
                        line-height: cfl(22,33);
                        margin-top: cfl(20,30);
                    }
                    @include _992{
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
                &__undertext{
                    font-size: 40.5px;
                    font-weight: 400;
                    line-height: 56px;
                    margin-top: 90px;
                    width: 80%;
                    display: flex;
                    @include _1920{
                        font-size: cfl(27,40.5);
                        line-height: cfl(37.3, 56);
                        margin-top: cfl(60,90);   
                    }
                    @include _576{
                        font-size: cflm(16,18);
                        line-height: cflm(18,20);
                        width: 100%;
                    }
                }
            }
        }
    }
}

.about-knife{
    margin-top: 120px;
    @include _1920{
        margin-top: cfl(80,120);
    }
    @include _992{
        margin-top: 40px;
    }
    &__title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        @include _1920{
            margin-bottom: cfl(33.3,50);
        }
        @include _992{
            margin-bottom: 15px;
        }
        span{
            text-transform: uppercase;
            font-size: 52.5px;
            line-height: 64px;
            @include _1920{
                font-size: cfl(35, 52.5);
                line-height: cfl(42.6,64);
            }
            @include _576{
                font-size: 14px;
                line-height: 16px;
                width: 65%;
            }
        }
        a{
            text-transform: uppercase;
            font-size: 21px;
            line-height: 42px;
            margin-top: 22px;
            transition: all .3s ease;
            @include _1920{
                font-size: cfl(14,21);
                line-height: cfl(28,42);
                margin-top: cfl(14.6, 22);
            }
            @include _576{
                font-size: cflm(8, 10);
                line-height: cflm(12,14);
            }
            svg{
                fill: #fff;
                transition: all .3s ease;
                width: 21px;
                height: 12px;
                transform: rotate(180deg);
                margin: -5px -4px 0 14px;
                @include _1920{
                    width: cfl(14,21);
                    height: cfl(8, 12);
                    margin: cfl(-3.3,-5) cfl(-2.6,-4) 0 cfl(9.3,14);
                }
                @include _576{
                    width: 10px;
                    height: 10px;
                }
            }
            &:hover{
                color: #ff0000;
                svg{
                    fill: #f00;
                }
                @include _992{
                    color: #fff;
                    svg{
                        color: #fff;
                    }
                }
            }
        }
    }
    &__list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 45px;
        @include _1920{
            column-gap: cfl(30, 45);
        }
        @include _576{
            grid-template-columns: 1fr;
            row-gap: 10px;
        }
    }
}



/*----------custom-modal----------*/
.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    z-index: 100;
    display: none;
}

.custom-modal.modal-opens, .custom-modal.modal-open {
    display: block;
}

.custom-modal#video-modal .custom-modal__wrapper {
    background-color: transparent;
}

.custom-modal#video-modal .custom-modal__body {
    padding: 5px 0 0;
}

.custom-modal#video-modal .close-modal__svg {
    fill: #fff;
}

.custom-modal__wrapper {
    width: 630px;
    max-width: calc(100% - 30px);
    margin: calc(0.01875 * 100vw + 90px) auto;
    background-color: #fff;
    opacity: 1;
}

.custom-modal__header {
    padding: 15px 0;
}

.custom-modal__body {
    padding: 3px calc(0.02813 * 100vw + 6px) 50px;
}

.custom-modal__content {
    display: none;
}

.modal-title {
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #333;
}

.close-modal {
    display: block;
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-left: auto;
    background-color: transparent;
    border: none;
}

.close-modal__svg {
    width: 100%;
    height: 100%;
    fill: #333;
    transition: .2s;
}

.close-modal:hover .close-modal__svg {
    fill: #EC412F;
}

.iframe-modal {
    display: block;
    width: 100%;
    height: 400px;
}

.modal-form {
    padding-top: 30px;
}