@import 'vendors/simple-scrollbar';

.container + .content-seo{
    margin: 70px 0 0;
    @include _1920{
        margin: cfl(46.67, 70) 0 0;
    }
}

.content-seo{
    $this: &;
    padding: 30px 0;
    @include _1920{
        padding: cfl(20, 30) 0;
    }
    &--bg{
        background: #F0F1F3;
    }
    &__wrapper{
        display: grid;
        grid-template-columns: 600px 1fr;
        grid-column-gap:90px;
        overflow: hidden;
        @include _1920{
            grid-template-columns: cfl(400, 600) 1fr;
            grid-column-gap: cfl(60, 90);
        }
        @include _576{
            grid-template-columns: repeat(1,1fr);
        }
        &--reverse{
            grid-template-columns: 1fr 600px;
            @include _1920{
                grid-template-columns: 1fr cfl(400, 600);
            }
            @include _576{
                grid-template-columns: repeat(1,1fr);
            }
            #{$this}__image{
                order: 1;
            }
            #{$this}__text{
                order: 2;
            }
        }
    }
    &__image{
        max-height: 605px;
        width: 100%;
        border-radius: 22.5px;
        overflow: hidden;
        @include _1920{
            // max-height: cfl(403.33, 605);
            max-height: cfl(450, 605);
           
            border-radius: cfl(15, 22.5);
        }
        img{
            width: 100%;
            height: 100%;
            // object-fit: cover;
            object-fit: contain;
        }
    }
    &__text{
        > .content-text{
            height: 100%;
            padding: 70px 0;
            @include _1920{
                padding: cfl(46.67, 70) 0;
            }
            @include _992{
                padding: 10px 0;
            }
        }

        .ss-content{
            padding-right: 45px;
            @include _1920{
                padding-right: cfl(30, 45);
            }
        }

        .ss-wrapper{
            position: relative;
            &::before{
                content: '';
                display: block;
                width: 6px;
                height: 100%;
                background-color: #E3E3E3;
                position: absolute;
                top: 0;
                right: 0;
                @include _1920{
                    width: cfl(4, 6);
                }
            }
        }

        .ss-scroll{
            background: $main-clr;
            opacity: 1 !important;
            width: 6px !important;
            border-radius: 0px !important;
            @include _1920{
                width: cfl(4, 6) !important;
            }
        }
        h1{
            font-size: 36px;
            line-height: 43.88px;
            font-weight: 500;
            text-transform: uppercase;
            width: 60%;
            margin-bottom: 34px;
            @include _1920{
                font-size: cfl(24, 36);
                line-height: cfl(29.25, 43.88);
                margin-bottom: cfl(22.67, 34);
            }
            @include _992{
                font-size: 20px;
                line-height: 24px;
                width: 90%;
            }
        }
        p{
            font-size: 21px;
            font-weight: 400;
            line-height: 32px;
            @include _1920{
                font-size: cfl(14, 21);
                line-height: cfl(21.33, 32);
            }
            @include _992{
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}
