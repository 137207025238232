.product-card{
    height: fit-content;
    cursor: pointer;
    position: relative;
    &:hover{
        .product-card__title{
            color: #ff0000;
        }
        .product-card__actions-cart{
            background-color: $main-clr;
        }
    }
    &__thumb{
        background-color: #272727;
        border-radius: 21px;
        padding: 83px 0 97px;
        position: relative;
        @include _1920{
            border-radius: cfl(14,21);
            padding: cfl(56, 83) 0 cfl(64, 97);
        }
        img{
            height: 358.5px;
            width: 100%;
            object-fit: cover;
            @include _1920{
                height: cfl(239, 358.5);
            }
            @include _576{
                height: 114px;
            }
        }
    }
    &__content{
        height:calc(.05734 * 70vw + -.1px);
        overflow: hidden;
        position:absolute;
        bottom: calc(.05734 * 12vw + -.1px);
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        padding: 0px 10px;
        @include _1920{
            margin-top: cfl(11,16);
        }
    }
    &__availability{
        position: absolute;
        bottom: 10px;
        right: 20px;
        color: $main-clr;
    }
    &__title{
        font-size: 24px;
        line-height: 42px;
        transition: color .3s ease;
        @include _1920{
            font-size: cfl(17,24);
            line-height: cfl(28,42);
        }
        @include _992{
            font-size: 15px;
            line-height: 32px;
        }
        @include _576{
            font-size: cflm(12,14);
            line-height: cflm(18, 21);
        }
    }
    &__price{
        &--current{
            color: #ADACAC;
            font-size: 21px;
            line-height: 42px;  
            font-weight: 600;
            @include _1920{
                font-size: cfl(14,21);
                line-height: cfl(28,42);
            }
            @include _992{
                font-size: 14px;
                line-height: 28px;
            }
            @include _576{
                font-size: cflm(12,14);
                line-height: cflm(20,24);
            }
        }
        &--new{
            font-size: 21px;
            line-height: 42px;
            font-weight: 600;
            color: #FF3232;
            margin-left: 30px;
            @include _1920{
                font-size: cfl(14,21);
                line-height: cfl(28,42);
                margin-left: cfl(20,30);
            }
            @include _992{
                font-size: 14px;
                line-height: 28px;
            }
            @include _576{
                font-size: cflm(12,14);
                line-height: cflm(20,24);
            }
        }
    }
    &__actions{
        position: absolute;
        top: 13.5px;
        right: 13.5px;
        @include _1920{
            top: cfl(9, 13.5);
            right: cfl(9, 13.5);
        }
        button{
            border-radius: 30px;
            padding: 3px 14px;
            background: transparent;
            transition: all .3s ease;
            @include _1920{
                padding: cfl(2,3) cfl(9, 14);
            }
            @include _992{
                background-color: $main-clr;
            }
            @include _576{
                padding: cflm(0,2) cflm(5,8);
            }
            span{
                font-weight: 100;
                font-size: 51px;
                line-height: 51px;
                color: #fff;
                @include _1920{
                    font-size: cfl(34,51);
                    line-height: cfl(34,51);
                }
                @include _576{
                    font-size: cflm(25,35);
                    line-height: cflm(25,35);
                }
                svg{
                    fill: none;
                    stroke: #fff;
                    max-width: 40px;
                    margin-left: 0px!important;
                }
            }
            svg{
                height: 30px;
                width: 30px;
                max-width: 0px;
                margin-left: 0px;
                fill: #fff;
                transition: all .3s ease;
                @include _1920{
                    height: cfl(20, 30);
                    width: cfl(20,30);
                }
            }
            &:hover{
                background-color: $main-clr;
                svg{
                    max-width: 30px;
                    margin-left: 17px;
                    @include _1920{
                        min-width: cfl(20,30);
                        margin-left: cfl(11,17);
                    }
                }
                @include _992{
                    svg{
                        max-width: 0px;
                        margin-left: 0px;
                    }
                }
            }
        }
    }
}